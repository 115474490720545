import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { GoogleMapsService } from './map.service';

@Component({
  selector: 'app-map',
  template: ` <div #map class="map h-full w-full"></div>`,
})
export class MapComponent implements AfterViewInit {
  // TODO da rifatorizzare
  @Input() set address(address: string) {
    if (address) {
      this.centerMapWithAddress(address);
    }
  }
  @ViewChild('map', { static: true }) mapElement: ElementRef;
  coordinates: { lat: number; lng: number };
  constructor(private gmapsService: GoogleMapsService) {}

  ngAfterViewInit(): void {
    if (!this.address) {
      this.centerMapWithAddress('Piazza del Colosseo, Roma, RM, Italia');
    } else {
      this.centerMapWithAddress(this.address);
      this.gmapsService.initMap(this.mapElement.nativeElement, {
        center: this.coordinates,
        zoom: 13,
      });
    }
  }

  centerMapWithAddress(address: string) {
    this.gmapsService
      .geocodeAddress(address)
      .then(coords => {
        this.coordinates = coords;
        this.gmapsService.initMap(this.mapElement.nativeElement, {
          center: this.coordinates,
          zoom: 13,
        });
      })
      .catch(error => {
        console.error('Error converting address to coords:', error);
      });
  }
}
