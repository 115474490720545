import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-stats-card',
  template: `
    <div
      class="w-full flex flex-col justify-content-center border border-neutral-200 rounded p-2">
      <p-chart
        class="w-1/2 m-4"
        type="doughnut"
        [data]="data"
        [options]="options"></p-chart>
      <div class="font-bold">{{ title }}</div>

      <div
        *ngFor="let label of labels; let i = index"
        class="flex items-center"
        [ngClass]="chartColors ? chartColors : ''">
        <div
          class="h-4 w-4 rounded-full mx-2"
          [style.background-color]="chartColors[i]"></div>
        <div class="text-darkgray">{{ values[i] + '% ' + label }}</div>
      </div>
    </div>
  `,
})
export class StatsCardComponent {
  @Input() title: string = '';
  @Input() isOpen: boolean = false;
  @Input() chartColors: Array<string> = ['#357E60', 'darkgray'];
  @Input() labels: Array<string> = [];
  @Input() values: Array<number> = [];
  data: any;
  options: any;

  ngOnInit() {
    this.data = {
      labels: this.labels ? this.labels : [],
      datasets: [
        {
          data: this.values ? this.values : [],
          backgroundColor: this.chartColors ? this.chartColors : [],
        },
      ],
    };

    this.options = {
      cutout: '60%',
      plugins: {
        legend: {
          display: false,
        },
      },
    };
  }
}
