import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-modal',
  template: `<div class="fixed inset-0 flex items-center justify-center z-50 bg-slate-100 bg-opacity-50">
    <div class="p-6 drop-shadow bg-white rounded-lg shadow-lg max-w-screen-md" [ngClass]="classes">
      <div class="flex justify-between items-center border-b pb-2 mb-2">
        <h2 class="text-xl font-bold">{{ title }}</h2>
        <button class="border border-gray text  font-bold py-2 px-4 rounded" (click)="closeModal.emit()">Close</button>
      </div>
      <div class="">
        <ng-content></ng-content>
      </div>
    </div>
  </div> `,
})
export class ModalComponent {
  @Input() title: string = '';
  @Input() classes: string = '';
  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();
}
