import {
  AfterContentInit,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  Output,
  QueryList,
} from '@angular/core';
import { AccordionPanelComponent } from './accordion-panel.component';

@Component({
  selector: 'app-accordion',
  template: `
    <div id="accordion-collapse" data-accordion="collapse">
      <ng-content></ng-content>
    </div>
  `,
})
export class AccordionComponent implements AfterContentInit {
  @Input() activePanel: number | string = 0;
  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();
  @ContentChildren(AccordionPanelComponent)
  accordionItems: QueryList<AccordionPanelComponent>;

  ngAfterContentInit() {
    this.accordionItems.forEach((item, index) => {
      item.panelId = index;
      item.clicked.subscribe((eventData: any) => {
        this.onPanelClick(eventData);
      });
    });
  }

  onPanelClick(panelId: number) {
    this.accordionItems.forEach(item => {
      item.isOpen = item.panelId === panelId;
    });
  }
}
