import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faFile } from '@fortawesome/free-regular-svg-icons';
@Component({
  selector: 'app-file',
  template: `
    <div class="flex items-center">
      <div
        class="bg-neutral-100 border border-neutral-200 rounded-xl w-12 h-12 flex justify-center items-center">
        <fa-icon [icon]="faFile" [ngClass]="isPresent ? 'text-primary' : 'text-gray'"></fa-icon>
      </div>
      <p class="flex-1 mx-2">{{ title }}</p>

      <button
        *ngIf="!isPresent"
        (click)="clicked.emit(1)"
        type="button"
        class="flex items-center border border-neutral-200 p-2 rounded-xl text-gray-500 hover:bg-gray-100">
        Carica
      </button>
    </div>
  `,
})
export class FileComponent {
  @Input() title: string = 'no title';
  @Input() isPresent: boolean = false;
  @Output() clicked: EventEmitter<number> = new EventEmitter<number>();
  protected readonly faFile = faFile;
}
