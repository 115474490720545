import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ProjectSaleEntity } from 'src/app/client';

@Component({
  selector: 'app-leads-list',
  template: `
    <div
      *ngFor="let customer of leads"
      class="border border-gray mb-5 mx-5 rounded p-5 cursor-pointer hover:border-primary "
      (click)="onClick.emit(customer)">
      <app-lead-item [customer]="customer" (iconClicked)="onLeadIconClick.emit($event)"></app-lead-item>
    </div>
  `,
})
export class LeadsListComponent {
  @Input() leads: Array<ProjectSaleEntity> = [];
  @Output() onClick: EventEmitter<ProjectSaleEntity> = new EventEmitter<ProjectSaleEntity>();
  @Output() onLeadIconClick: EventEmitter<ProjectSaleEntity> = new EventEmitter<ProjectSaleEntity>();
}
