import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-stats-progress',
  template: `
    <div>
      <p class="my-2">{{ label }}</p>
      <app-progress-bar [value]="percentage"></app-progress-bar>
      <div class="flex justify-between my-2">
        <p class="font-bold">{{ mainValue | currency : 'EUR' : 'symbol' }}</p>

        <p class="text-neutral-400">
          {{ (secondaryValue | currency : 'EUR' : 'symbol') + modifier }}
        </p>
      </div>
    </div>
  `,
})
export class StatsProgressComponent {
  @Input() percentage: number = 0;
  @Input() mainValue: number = 100;
  @Input() secondaryValue: number = 120;
  @Input() label: string = 'label';
  @Input() modifier: string = '/mq';
}
