import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-accordion-panel',
  template: `
    <div class="border-gray border-y">
      <h2>
        <button
          (click)="clicked.emit(panelId)"
          type="button"
          class="flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 hover:bg-gray-100">
          <span class="font-bold" [class.text-primary]="isOpen">{{
            title
          }}</span>
          <svg
            data-accordion-icon
            class="w-3 h-3 shrink-0"
            [class.rotate-180]="isOpen"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6">
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9 5 5 1 1 5" />
          </svg>
        </button>
      </h2>
      <div *ngIf="isOpen" class="p-5">
        <ng-content></ng-content>
      </div>
    </div>
  `,
})
export class AccordionPanelComponent {
  @Input() title: string = '';
  @Input() isOpen: boolean = false;
  @Input() panelId: number;
  @Output() clicked: EventEmitter<number> = new EventEmitter<number>();
}
