import { Component, Input } from '@angular/core';
import { Lead } from '@models/lead.interface';
import { ProjectSaleEntity } from 'src/app/client';


@Component({
  selector: 'app-anagraphic',
  template: `
    <div class="border border-gray mb-5 rounded p-5 cursor-pointer">
      <h3 class="font-bold">Dati</h3>
      <p class="font-bold mt-4">Cognome e Nome</p>
      <p class="mb-4 text-neutral-700">
        {{ customer?.data?.contactNominative }}
      </p>
      <hr />
      <p class="font-bold mt-4">Telefono</p>
      <p class="mb-4 text-neutral-700">{{ customer?.data?.contactObj?.phones?.[0]?.phoneNumber }}</p>
      <hr />
      <p class="font-bold mt-4">Email</p>
      <p class="mb-4 text-neutral-700">{{ customer?.data?.contactObj?.emails?.[0]?.email }}</p>
      <hr />
      <p class="font-bold mt-4">Indirizzo</p>
      <p class="mb-4 text-neutral-700">{{ customer?.data?.propertyObj?.data?.address?.formatted_address }}</p>
      <hr />
      <p class="font-bold mt-4">Data e ora</p>
      <p class="mb-4 text-neutral-700">

      </p>
      <hr />
      <p class="font-bold mt-4">Tipo</p>
      <p class="mb-4 text-neutral-700">{{ customer?.type }}</p>
      <hr />
      <p class="font-bold mt-4">Stato</p>
      <p class="mb-4 text-neutral-700">{{ customer?.status?.lead }}</p>
      <hr />
    </div>
  `,
})
export class AnagraphicComponent {
  @Input() customer: ProjectSaleEntity | null;
}
