import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faTimeline } from '@fortawesome/free-solid-svg-icons';
import { ProjectSaleEntity } from 'src/app/client';
@Component({
  selector: 'app-lead-item',
  template: `
    <div class="relative">
      <div class="absolute right-0 top-0 border rounded-full px-2 py-1">
        {{ customer.status.lead ? statusLabels[customer.status.lead] : '---' }}
      </div>
      <div>
        <div class="text-neutral-500 font-bold text-sm">
          {{ customer.type }}
        </div>
        <p>
          <span class="font-bold">{{ customer.data.contactNominative }}</span
          >
        </p>
        <p>
          {{ customer.data.contactObj?.phones?.[0]?.phoneNumber + ' ' + customer.data.contactObj?.emails?.[0]?.email }}
        </p>
        <p>
          {{ customer.data.propertyObj?.data?.address?.formatted_address ?? '' }}
        </p>
        <div class="flex justify-between">
          <p>
            <!-- <span> {{ customer.createdAt | date : 'dd/MM/yyyy' }} </span
            > --><span>
              <!-- {{ customer.cache?.data?.property?.createdAt | date : 'HH:mm' }} -->
            </span>
          </p>
          <fa-icon
            *ngIf="showIcon"
            [icon]="faTimeline"
            class="mx-2 text-primary p-2"
            (click)="iconClicked.emit(customer); $event.stopPropagation()"></fa-icon>
        </div>
      </div>
    </div>
  `,
})
export class LeadItemComponent {
  @Input() customer: ProjectSaleEntity;
  @Input() showIcon: boolean = true;
  @Output() iconClicked: EventEmitter<ProjectSaleEntity> = new EventEmitter<ProjectSaleEntity>();
  protected readonly faTimeline = faTimeline;
  statusLabels = [
    '---',
    'Primo appuntamento',
    'Da valutare',
    'Valutazione consegnata',
    'Standby',
    'Incarico',
    'Proposta trattativa',
    'Venduto',
    'Appuntamento da fissare',
    'KO',
    'KO Sopralluogo',
    'Richiesta contestazione',
    'KO Gestione',
    'KO Recesso',
    'KO Incarico Scaduto',
  ];
}
