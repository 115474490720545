import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

export interface SelectOption {
  value: any;
  label: string;
}

@Component({
  selector: 'app-select',
  template: `
    <div class="h-full border rounded-xl p-2" [ngClass]="errorText ? 'border-red-500' : 'border-gray'">
      <span class="text-neutral-500">{{ label ? label : '' }}</span>
      <select class="w-full border-none outline-none bg-white" [(ngModel)]="value">
        <option *ngFor="let option of options" [value]="option.value">
          {{ option.label }}
        </option>
      </select>
    </div>
    <small *ngIf="errorText" class="!text-red-500 mx-2 bg-transparent w-full">{{ errorText }}</small>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true,
    },
  ],
})
export class SelectComponent {
  @Input() options: SelectOption[] = [];
  @Input() label: string;
  @Input() errorText: string;
  @Output() changeEvent: EventEmitter<any> = new EventEmitter<any>();
  private _value: any;

  @Input() set value(val: any) {
    this._value = val;
    this.changeEvent.emit(val);
    this.onChange(val);
    this.onTouch(val);
  }

  get value() {
    return this._value;
  }

  onChange: any = (event: any) => {};
  onTouch: any = () => {};

  // this method sets the value programmatically
  writeValue(value: any) {
    this.value = value;
  }
  // upon UI element value changes, this method gets triggered
  registerOnChange(fn: any) {
    this.onChange = fn;
  }
  // upon touching the element, this method gets triggered
  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }
}
