import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-selector',
  template: `
    <div
      *ngIf="isBoxed; else buttonsBlock"
      class="border border-gray rounded-xl p-2">
      <span class="text-neutral-500">{{ label }}</span>
      <ng-container *ngTemplateOutlet="buttonsBlock"></ng-container>
    </div>

    <ng-template #buttonsBlock>
      <div
        class="border border-gray rounded-xl flex justify-around overflow-hidden">
        <div
          *ngFor="let button of buttons; let i = index"
          class="flex-1 text-center"
          [class.py-2]="!isSmall"
          [ngClass]="i == activeButtonIndex ? 'bg-primary text-white' : ''"
          (click)="onButtonClick(i)">
          {{ button.label }}
        </div>
      </div>
    </ng-template>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectorComponent {
  @Input() buttons: Array<{ label: string; value: string }> = [
    { label: 'Si', value: 'yes' },
    { label: 'No', value: 'no' },
    { label: 'n.d.', value: 'nd' },
  ];
  @Input() activeButtonIndex: number = 0;
  @Input() label: string;
  @Input() isBoxed: boolean = false;
  @Input() isSmall: boolean = false;
  @Output() buttonClicked: EventEmitter<{ label: string; value: string }> =
    new EventEmitter<{ label: string; value: string }>();

  onButtonClick(clickedButtonIndex: number) {
    this.activeButtonIndex = clickedButtonIndex;
    this.buttonClicked.emit(this.buttons[clickedButtonIndex]);
  }
}
