import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  template: `
    <p-progressBar
      [value]="value"
      [showValue]="false"
      [color]="color"
      [styleClass]="'h-2'"></p-progressBar>
  `,
})
export class ProgressBarComponent {
  @Input() value: number = 0;
  @Input() label: string = 'label';
  @Input() color: string = '#357E60';
}
