import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  faHouse,
  faChartColumn,
  faHouseFlag,
  faHouseChimneyUser,
  faFileSignature,
} from '@fortawesome/free-solid-svg-icons';
import {
  faAddressBook,
  faFileLines,
  faUser,
  faComments,
  faSquareCheck,
} from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'app-section-selector',
  template: `
    <div class="w-full">
      <!-- Grid Layout -->
      <div *ngIf="gridLayout" class="grid grid-cols-2 gap-4">
        <div
          class="p-4 border border-gray rounded-xl flex cursor-pointer"
          *ngFor="let tile of tiles"
          (click)="sectionClicked.emit(tile.slug)">
          <fa-icon [icon]="tile.icon" class="mx-2 text-primary"></fa-icon>
          {{ tile.title }}
        </div>
      </div>

      <!-- Inline Layout -->
      <div *ngIf="!gridLayout" class="flex overflow-x-auto space-x-4">
        <div
          class="w-auto min-w-2/5 flex-shrink-0 p-4 border border-gray rounded-xl flex cursor-pointer "
          *ngFor="let tile of tiles"
          (click)="sectionClicked.emit(tile.slug)">
          <fa-icon [icon]="tile.icon" class="mx-2 text-primary"></fa-icon>
          {{ tile.title }}
        </div>
      </div>
    </div>
  `,
})
export class SectionSelectorComponent {
  @Input() gridLayout: boolean = true;
  tiles = [
    { id: 1, title: 'Contatto', icon: faAddressBook, slug: 'lead' },
    { id: 2, title: 'Immobile', icon: faHouse, slug: 'property' },
    { id: 3, title: 'Documenti', icon: faFileLines, slug: 'documents' },
    { id: 4, title: 'Proprietario', icon: faUser, slug: 'owner' },
    { id: 5, title: 'Intervista', icon: faComments, slug: 'interview' },
    { id: 6, title: 'Valutazione', icon: faChartColumn, slug: 'valutation' },
    { id: 7, title: 'Pubblicazione', icon: faHouseFlag, slug: 'publication' },
    { id: 8, title: 'Incarico', icon: faSquareCheck, slug: 'assignment' },
    { id: 9, title: 'Visite', icon: faHouseChimneyUser, slug: 'visits' },
    { id: 10, title: 'Proposte', icon: faFileSignature, slug: 'proposals' },
  ];
  faAddressBook = faAddressBook;
  @Output() sectionClicked: EventEmitter<string> = new EventEmitter<string>();
}
