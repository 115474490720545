import { Injectable } from '@angular/core';

declare let google: any;

@Injectable({
  providedIn: 'root'
})
export class GoogleMapsService {
  private googleMaps: any;
  private geocoder: any;
  constructor() {
    this.geocoder = new google.maps.Geocoder();
  }
  initMap(mapElement: HTMLElement, options: any): void {
    this.googleMaps = new google.maps.Map(mapElement, options);
  }

  geocodeAddress(address: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.geocoder.geocode({ address }, (results: { geometry: { location: any; }; }[], status: string) => {
        if (status === 'OK') {
          const location = results[0].geometry.location;
          resolve({ lat: location.lat(), lng: location.lng() });
        } else {
          reject(`Geocode was not successful for the following reason: ${status}`);
        }
      });
    });
  }}
