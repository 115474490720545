import { ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { EInputType } from './input-type-enum';

@Component({
  selector: 'app-input',
  template: `
    <div class="h-full border rounded-xl p-2" [ngClass]="errorText ? 'border-red-500' : 'border-gray'">
      <span class="text-neutral-500">{{ label ? label : '' }}</span>
      <input [type]="type" class="w-full border-none outline-none" [placeholder]="''" [(ngModel)]="value" />
    </div>
    <small *ngIf="errorText" class="!text-red-500 mx-2 bg-transparent w-full">{{ errorText }}</small>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true,
    },
  ],
})
export class InputComponent implements ControlValueAccessor {
  @Input() type: EInputType | string = EInputType.text;
  @Input() label: string;
  @Input() placeholder: string = '';
  @Input() disabled: boolean = false;
  @Input() icon: { name?: string; classes?: string };
  @Input() errorText: string;
  @Output() changeEvent: EventEmitter<any> = new EventEmitter<any>();

  passwordRevealed = false;

  private _value: any;

  get value() {
    return this._value;
  }

  @Input() set value(val: any) {
    this._value = val;
    this.onChange(val);
    this.onTouch(val);
  }
  onChange: any = () => {};
  onTouch: any = () => {};

  // this method sets the value programmatically
  writeValue(value: any) {
    this.value = value;
  }
  // upon UI element value changes, this method gets triggered
  registerOnChange(fn: any) {
    this.onChange = fn;
  }
  // upon touching the element, this method gets triggered
  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }
}
