import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LeadsListComponent } from './components/leads-list/leads-list.component';
import { LeadItemComponent } from './components/leads-list/lead-item.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SectionSelectorComponent } from './components/selector/section-selector.component';
import { AnagraphicComponent } from './components/anagraphic/anagraphic.component';
import { InputComponent } from './components/input/input.component';
import { SelectorComponent } from './components/buttons-selector/selector.component';
import { ModalComponent } from './components/modal/modal.component';
import { AccordionComponent } from './components/accordion/accordion.component';
import { AccordionPanelComponent } from './components/accordion/accordion-panel.component';
import { FileComponent } from './components/file/file.component';
import { StatsCardComponent } from './components/stats-card/stats-card.component';
import { ChartModule } from 'primeng/chart';
import { MapComponent } from './components/map/map.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { ProgressBarModule } from 'primeng/progressbar';
import { StatsProgressComponent } from './components/stats-progress/stats-progress.component';
import { SelectComponent } from './components/select/select.component';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { FlatpickrModule } from 'angularx-flatpickr';

const componentList = [
  LeadsListComponent,
  LeadItemComponent,
  SectionSelectorComponent,
  AnagraphicComponent,
  InputComponent,
  SelectComponent,
  SelectorComponent,
  ModalComponent,
  AccordionComponent,
  AccordionPanelComponent,
  FileComponent,
  StatsCardComponent,
  MapComponent,
  ProgressBarComponent,
  StatsProgressComponent,
  DatePickerComponent,
];
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    ChartModule,
    ProgressBarModule,
    FlatpickrModule,
  ],
  declarations: [componentList],
  exports: [componentList],
})
export class SharedModule {}
